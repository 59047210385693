<template>
  <div class="SurveyList-wrapper">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="新增问卷"
                  permission="add"
                  @click="createSurvey"></v-button>
        <v-button text="分类管理"
                  permission="category"
                  @click="categoryMgt"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="调查名称"
                 v-model="searchParams.title"></v-input>
        <v-select label="调查类型"
                  v-model="searchParams.categoryId"
                  :options="categoryOps"></v-select>
        <v-input label="发起方"
                 v-model="searchParams.sponsorName"></v-input>
        <v-select label="是否抽奖"
                  v-model="searchParams.isLottery"
                  :options="isLotteryOps"></v-select>
        <v-select label="状态"
                  v-model="searchParams.state"
                  :options="statusOps"></v-select>
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司"
                   v-model="searchParams.regionId"
                   v-bind="reginParams"></v-select2>
        <v-datepicker label="创建时间"
                      :startTime.sync="searchParams.fromTime"
                      :endTime.sync="searchParams.toTime"
                      type="rangedatetimer"></v-datepicker>
        <v-datepicker label="开始时间"
                      :startTime.sync="searchParams.beginFromTime"
                      :endTime.sync="searchParams.beginToTime"
                      type="rangedatetimer"></v-datepicker>
        <v-datepicker label="结束时间"
                      :startTime.sync="searchParams.endFromTime"
                      :endTime.sync="searchParams.endToTime"
                      type="rangedatetimer"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  permission="update"
                  @click="editSurvey(scope.row)"></v-button>
        <el-dropdown trigger="click"
                     @command="moreHandle"
                     placement="bottom">
          <span class="el-dropdown-link">
            更多
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ row: scope.row, type: 1 }"
                              v-show="permission.resultView === 1">
              <span>查看结果</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 2 }"
                              v-show="permission.participantView === 1">
              <span>查看参与人</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 3 }"
                              v-show="permission.drawUrl === 1">
              <span>设置抽奖</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 4 }"
                              v-show="permission.copy === 1">
              <span>复制问卷</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </template>
    </list>

    <el-dialog title="查看参与项目"
               :visible.sync="communityVisible">
      <div style="text-align:left">{{ communityNames }}</div>
    </el-dialog>

    <el-dialog title="设置抽奖链接"
               :visible.sync="drawVisible"
               width="450px">
      <el-form ref="drawform"
               :model="drawForm"
               :rules="drawFormRules"
               label-width="90px">
        <el-form-item label="抽奖链接:"
                      prop="drawUrl">
          <v-input v-model="drawForm.drawUrl"
                   placeholder="请输入抽奖链接"
                   maxlength="500"
                   :width="300" />
        </el-form-item>
        <span>请填写以https://开头的网址</span>
        <el-form-item>
          <v-button type="primary"
                    @click="submitDrawUrl">保存</v-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { getListURL, exportListURL, getCategoryListURL, getParticipationCommunityURL, setDrawURL } from './api'
import { communityParams, regionParams } from 'common/select2Params'
import { isLotteryOps, statusOps, statusMap } from './map'
import { createHTMLVNode, createAlinkVNode } from 'common/vdom'
export default {
  name: 'SurveyList',
  data () {
    let _this_ = this
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps,
      isLotteryOps: isLotteryOps,
      categoryOps: [],
      communityParams: communityParams,
      reginParams: regionParams,
      searchParams: {
        title: '',
        categoryId: undefined,
        sponsorName: '',
        isLottery: undefined,
        state: undefined,
        communityId: '',
        regionId: '',
        fromTime: '',
        toTime: '',
        beginFromTime: '',
        beginToTime: '',
        endFromTime: '',
        endToTime: ''
      },
      headers: [
        {
          prop: 'title',
          label: '调查名称'
        },
        {
          prop: 'categoryName',
          label: '调查类型'
        },
        {
          prop: 'sponsorName',
          label: '调查发起'
        },
        {
          prop: 'communityName',
          label: '参与项目',
          formatter (row, prop) {
            return createAlinkVNode(_this_, row, prop, {
              text: row.communityName,
              cb: _this_.getAllCommunity
            })
          }
        },
        {
          prop: 'joinUserCnt',
          label: '参与人次',
          align: 'right'
        },
        {
          prop: 'isLottery',
          label: '是否抽奖',
          formatter (row) {
            return row.drawUrl ? '是' : '否'
          }
        },
        {
          prop: 'statusTxt',
          label: '调查状态',
          formatter (row) {
            return statusMap[row.state]
          }
        },
        {
          prop: 'effectiveTime',
          label: '调查有效时间',
          width: 150,
          formatter: (row, prop) => {
            let time = row.beginTime + '~<br/>' + row.endTime
            return createHTMLVNode(this, time)
          }
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ],
      communityVisible: false,
      drawVisible: false,
      communityNames: '',
      drawForm: {
        drawUrl: '',
        id: ''
      },
      drawFormRules: {
        drawUrl: [
          { required: true, message: '请输入抽奖链接', trigger: 'blur' },
          { pattern: /(https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/, message: '请输入正确的url链接' }
        ]
      }
    }
  },
  computed: {
    permission: function () {
      return this.$store.state.permission
    }
  },
  created () {
    this.getCategoryOps()
    console.log(this.permission)
  },
  methods: {
    // 获取调查类型的ops
    getCategoryOps () {
      let _this_ = this
      _this_.categoryOps = [{
        text: '全部',
        value: undefined
      }]
      this.$axios.get(getCategoryListURL).then(res => {
        if (res.status === 100) {
          if (res.data && res.data.length > 0) {
            res.data.forEach(item => {
              _this_.categoryOps.push({
                text: item.value,
                value: item.id
              })
            })
          }
        }
      })
    },
    // 查看全部参与项目
    getAllCommunity (row) {
      let communityName = ''
      let _this_ = this
      if (row.communityName.length > 200) {
        this.$axios.get(getParticipationCommunityURL + '?' + _this_.$qs.stringify({ id: row.id })).then(res => {
          if (res.status === 100) {
            if (res.data && res.data.length) {
              res.data.forEach(item => {
                communityName += item + '、'
              })
              _this_.communityNames = communityName.substring(0, communityName.length - 1)
              _this_.communityVisible = true
            }
          }
        })
      } else {
        communityName = row.communityName
        this.communityNames = communityName
        this.communityVisible = true
      }
    },
    // 保存抽奖链接
    submitDrawUrl () {
      let _this_ = this
      this.$refs['drawform'].validate((valid) => {
        console.log(valid)
        if (valid) {
          console.log(_this_.drawForm)
          _this_.$axios.put(setDrawURL, _this_.$qs.stringify(_this_.drawForm)).then(res => {
            if (res.status === 100) {
              _this_.$message({ message: '设置抽奖链接成功', type: 'success' })
              _this_.drawVisible = false
            }
          })
        }
      })
    },
    // 新建问卷
    createSurvey () {
      this.$router.push({
        name: 'surveyForm'
      })
    },
    // 编辑问卷
    editSurvey (row) {
      this.$router.push({
        name: 'surveyForm',
        query: {
          id: row.id
        }
      })
    },
    // 更多操作
    moreHandle (val) {
      console.log(val)
      if (val.type === 1) {
        // 查看结果
        this.$router.push({
          name: 'surveyResult',
          query: {
            id: val.row.id
          }
        })
      } else if (val.type === 2) {
        // 查看参与人
        this.$router.push({
          name: 'surveyParticipationList',
          query: {
            id: val.row.id
          }
        })
      } else if (val.type === 3) {
        // 设置抽奖
        this.drawVisible = true
        this.drawForm.id = val.row.id
      } else if (val.type === 4) {
        // 复制问卷
        this.$router.push({
          name: 'surveyForm',
          query: {
            id: val.row.id,
            isCopy: 1
          }
        })
      }
    },
    // 分类管理
    categoryMgt () {
      this.$router.push({
        name: 'surveyCategoryList'
      })
    }
  }
}
</script>
